/**
 * MIconHeadlineTextLine
 *
 * @param {String} name of the icon.
 *
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import withStyles from '@material-ui/styles/withStyles'

import Aline from '../01-atoms/a-line'

const styles = theme => ({
  container: {
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
    '& svg': {
      width: '100px',
      height: '100px',
      float: 'left',
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        position: 'absolute',
        left: '-205px',
        top: '0px',
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(3),
      },
      [theme.breakpoints.up('sm')]: {
        flex: '0 0 178px',
        height: '178px',
        width: '178px',
        marginRight: theme.spacing(4),
      },
    },
  },
  inner: {
    '& h2': {
      fontSize: '20px',
      wordBreak: 'break-word',
      marginBottom: '10px',
      fontWeight: 400,
      [theme.breakpoints.up('sm')]: {
        fontSize: '40px',
        lineHeight: '44px',
      },
    },
    '& p': {
      fontSize: '16px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '18px',
        lineHeight: '28px',
      },
    },
  },
})

class MIconHeadlineTextLine extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes, icon, children, ...props } = this.props
    const icon_svg = icon ? icon : ''

    return (
      <div className={classes.container} {...props}>
        {icon_svg}
        <div className={classNames(classes.inner)}>
          {children}
          <Aline />
        </div>
      </div>
    )
  }
}

MIconHeadlineTextLine.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  icon: PropTypes.object,
}

export default withStyles(styles)(MIconHeadlineTextLine)
