import React from 'react'
import PropTypes from 'prop-types'

import Packages from '../03-organisms/o-packages'

class OPackageChangeForm extends React.Component {
  constructor(props) {
    super(props)
    this.addPackageType = this.addPackageType.bind(this)
  }

  addPackageType(type) {
    const formInput = {
      subject: 'Paketwechsel',
      message: 'User möche wechseln zu Paket: ' + type,
    }

    this.props.mailRequest({
      type: 'changePackage',
      data: formInput,
      userId: this.props.userId,
      userToken: this.props.userToken,
    })
  }

  render() {
    return (
      <Packages
        buttonType="submit"
        addPackageType={this.addPackageType}
        buttonText="Paket auswählen"
      />
    )
  }
}

OPackageChangeForm.defaultProps = {
  subject: '',
}

OPackageChangeForm.propTypes = {
  userId: PropTypes.number.isRequired,
  userToken: PropTypes.string.isRequired,
  mailRequest: PropTypes.func.isRequired,
}

export default OPackageChangeForm
