import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import withStyles from '@material-ui/styles/withStyles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import AButton from '../01-atoms/a-button'
import ARemove from '../01-atoms/a-icon-remove'
import AInput from '../01-atoms/a-input'
import AEditor from '../01-atoms/a-editor'

// import ASpinner from '../01-atoms/a-spinner'

const styles = theme => ({
  removeContainer: {
    display: 'flex',
  },
  removeReference: {
    alignSelf: 'center',
  },
  removeImage: {
    position: 'absolute',
    zIndex: 1,
    right: theme.typography.pxToRem(12),
  },
  referenceImageContainer: {
    position: 'relative',
  },
  referenceImage: {
    width: '100%',
  },
  input: {
    display: 'none',
  },
  button: {
    backgroundColor: theme.customStyles.extraColors.darkGrey,
    color: theme.customStyles.extraColors.white,
    minWidth: theme.typography.pxToRem(215),
    marginBottom: theme.typography.pxToRem(30),
  },
})

/** MProfileReferences Component */
class MProfileReferences extends Component {
  constructor(props) {
    super(props)
  }

  handleChangeReferenceImage(e, i) {
    if (typeof this.props.handleChangeReferenceImage === 'function') {
      this.props.handleChangeReferenceImage(e, i)
    }
  }

  handleChangeReference(elem, i, field) {
    if (typeof this.props.handleChangeReference === 'function') {
      this.props.handleChangeReference(elem, i, field)
    }
  }

  addReference() {
    if (typeof this.props.addReference === 'function') {
      this.props.addReference()
    }
  }

  render() {
    const {
      classes,
      references,
      handleDeleteReferenceImage,
      removeReference,
    } = this.props

    return (
      <Fragment>
        {references
          ? references.map((reference, i) => {
              // do not filter earlier, otherwise editing has an error after removing one item
              return reference.status != 'deleted' ? (
                <Fragment key={i}>
                  {typeof reference.image == 'object' &&
                  reference.image.status != 'deleted' ? (
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      className={classes.referenceImageContainer}
                    >
                      <ARemove
                        className={classes.removeImage}
                        remove={handleDeleteReferenceImage}
                        functionParam={i}
                      />
                      <img
                        className={classes.referenceImage}
                        src={reference.image.src}
                        srcSet={
                          reference.image.srcset
                            ? reference.image.srcset
                            : undefined
                        }
                        alt={reference.image.title}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={4}>
                      <input
                        className={classes.input}
                        accept="image/jpg, image/jpeg, image/png"
                        id={`file-upload-reference-image-${i}`}
                        type="file"
                        onChange={e => this.handleChangeReferenceImage(e, i)}
                      />
                      <label htmlFor={`file-upload-reference-image-${i}`}>
                        <Button
                          className={classes.button}
                          variant="contained"
                          component="span"
                        >
                          Bild Upload
                        </Button>
                      </label>
                      <Typography component="p" className={classes.description}>
                        Bild sollte mindestens 400px breit sein, Format: png,
                        jpg, jpeg
                      </Typography>
                    </Grid>
                  )}

                  <Grid item xs={10} sm={6}>
                    <AInput
                      type="text"
                      label="Titel"
                      onChange={this.handleChangeReference.bind(
                        this,
                        i,
                        'headline'
                      )}
                      value={
                        reference.headline !== undefined
                          ? reference.headline
                          : ''
                      }
                    />
                    <AEditor
                      index={i}
                      type="reference"
                      label="Text"
                      name="company_info.about_us"
                      onChangeParent={this.handleChangeReference.bind(
                        this,
                        i,
                        'text'
                      )}
                      onEnter={false}
                      value={reference.text !== undefined ? reference.text : ''}
                    />
                    <AInput
                      type="text"
                      label="Link"
                      onChange={this.handleChangeReference.bind(
                        this,
                        i,
                        'link'
                      )}
                      value={reference.link !== undefined ? reference.link : ''}
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.removeContainer}>
                    <ARemove
                      className={classes.removeReference}
                      remove={removeReference}
                      functionParam={i}
                    />
                  </Grid>
                </Fragment>
              ) : (
                false
              )
            })
          : false}
        {references == null ||
        (references &&
          references.filter(reference => reference.status != 'deleted').length <
            7) ? (
          <Grid item xs={12} sm={10} align="right">
            <AButton
              className={classes.button}
              variant="contained"
              onClick={() => this.addReference()}
            >
              Referenzen hinzufügen
            </AButton>
            <Typography component="p">
              Sie können noch{' '}
              {references == null
                ? '7'
                : 7 -
                  references.filter(reference => reference.status != 'deleted')
                    .length}{' '}
              Referenzen hochladen
            </Typography>
          </Grid>
        ) : (
          ''
        )}
      </Fragment>
    )
  }
}

MProfileReferences.propTypes = {
  classes: PropTypes.object.isRequired,
  references: PropTypes.array,
  addReference: PropTypes.func,
  removeReference: PropTypes.func,
  handleChangeReference: PropTypes.func,
  handleChangeReferenceImage: PropTypes.func,
  handleDeleteReferenceImage: PropTypes.func,
}

export default withStyles(styles)(MProfileReferences)
