import React from 'react'

import * as validations from '../00-utils/u-validations'

class AForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: [],
      fields: {},
      formValid: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  /**
   * Function to get all values from registered fields of this form without errors
   *
   * @param {Boolean} stringify If the returned object should be stringified or // NOTE:
   *
   * @return {Object|string} Object if not stringified or stringified object
   */
  getValues(stringify = true) {
    let values = {}
    const $this = this

    Object.keys($this.state.fields).map(function(key) {
      values[key] = $this.state.fields[key].value
    })

    return stringify ? JSON.stringify(values) : values
  }

  /**
   * Function to be passed to child components:
   * Registers the field for form validation and sets default state
   *
   * @param {String} name The name of the field
   */
  fieldRegister(name, value = '') {
    this.setState(prevState => ({
      fields: { ...prevState.fields, [name]: { error: false, value: value } },
    }))
  }

  /**
   * Validation runner for a field
   *
   * @param {String} value The value to test
   * @param {Array} tests The tests against the value is run
   *
   * @return {Boolean} If all tests passed or a test failed
   */
  fieldValidate(value, tests) {
    // every runs a function on every element of an Array.
    // It will stop on the first failed test (when the function
    // returns false)
    return tests.every(test => {
      return validations[test](value)
    })
  }

  /**
   * Simple test if a field has an Error
   *
   * @param {String} name Name of the field to test
   *
   * @return {Boolean}
   */
  fieldHasError(name) {
    return typeof this.state.fields[name] != 'undefined'
      ? this.state.fields[name].error
      : false
  }

  /**
   * Validates the form: searches for errors in fields.
   * If none of the fields has an error, the form is valid.
   */
  formValidate() {
    const formValid = Object.keys(this.state.fields).filter(x => {
      return this.state.fields[x].error
    })

    this.setState(prevState => ({
      ...prevState,
      formValid: formValid.length == 0,
      errors: formValid,
    }))
  }

  handleChange(tests, event) {
    const name = event.target.name
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    let error = false

    // FIRST: check if this field is required
    if (event.target.required) {
      error = value.length == 0
    }

    // SECOND: if passes first check and field is not cleared,
    // try to validate this field. Validation is NOT needed for fields,
    // that have been cleared and are not required.
    if (!error && (value.length > 0 || typeof value === 'boolean')) {
      error = !this.fieldValidate(value, tests)
    }

    // THIRD: set state and validate the whole form via setState callback
    this.setState(
      prevState => ({
        fields: {
          ...prevState.fields,
          [name]: { error: error, value: value },
        },
      }),
      () => this.formValidate()
    )
  }

  /**
   * Will be called after the form validates.
   * Useful for redirecting to another page
   */
  afterSubmit() {}

  handleSubmit(event) {
    event.preventDefault()

    if (this.state.formValid) {
      window.scrollTo(0, 0)
    } else {
      // eslint-disable-next-line no-console
      // console.log('form is NOT valid. Not submitting …')
      return false
    }

    this.afterSubmit()
  }
}

export default AForm
