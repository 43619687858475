import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/styles/withStyles'
import classnames from 'classnames'

import IconRemove from './../../assets/svgs/icon-remove.svg'

const styles = theme => ({
  removeIcon: {
    fontSize: theme.typography.pxToRem(18),
    backgroundColor: theme.customStyles.extraColors.white,
    padding: theme.typography.pxToRem(10),
    cursor: 'pointer',
    height: 38,
    width: 38,
    '& svg': {
      fill: theme.customStyles.extraColors.darkGrey,
    },
  },
})

/** ALine Component */
class AIconRemove extends Component {
  constructor(props) {
    super(props)
  }

  handleRemove() {
    if (typeof this.props.remove === 'function') {
      this.props.remove(this.props.functionParam)
    }
  }

  render() {
    const { classes, className } = this.props

    return (
      <div
        className={classnames(className, classes.removeIcon)}
        onClick={() => this.handleRemove()}
      >
        <IconRemove width="18" height="18" />
      </div>
    )
  }
}

AIconRemove.propTypes = {
  classes: PropTypes.object.isRequired,
  remove: PropTypes.func,
  functionParam: PropTypes.any,
  className: PropTypes.string,
}

export default withStyles(styles)(AIconRemove)
