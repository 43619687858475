/**
 * ACollapse
 *
 * @param {String} headline - the headline of the collapse element
 * @param {Boolean} premium - enable premium icon
 * @param {Boolean} open - open from the beginning
 *
 */

import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/styles/withStyles'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import classnames from 'classnames'
import { Typography } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'

import AIconPremium from '../01-atoms/a-icon-premium'

const styles = theme => ({
  container: {
    display: 'flex',
    marginBottom: theme.typography.pxToRem(36),
    alignItems: 'center',
    justifyContent: 'center',
    '& h2': {
      flexGrow: 1,
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(26),
      fontWeight: 500,

      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.pxToRem(25),
        lineHeight: theme.typography.pxToRem(33),
      },
    },
  },
  expand: {
    width: theme.typography.pxToRem(48),
    height: theme.typography.pxToRem(48),
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  divider: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
    },
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
})

class ACollapse extends Component {
  constructor(props) {
    super(props)
    this.state = { expanded: false }

    // This binding is necessary to make `this` work in the callback
    this.handleExpandClick = this.handleExpandClick.bind(this)
  }

  handleExpandClick() {
    this.setState(prevState => ({
      expanded: !prevState.expanded,
    }))
  }

  componentDidMount() {
    if (this.props.open) {
      this.setState({
        expanded: true,
      })
    }
  }

  render() {
    const { classes, children, headline, premium } = this.props
    const icon = premium ? <AIconPremium medium /> : false

    return (
      <Fragment>
        <div className={classes.container}>
          <Typography variant="h2">{headline}</Typography>
          {icon}
          <IconButton
            className={classnames(classes.expand, {
              [classes.expandOpen]: this.state.expanded,
            })}
            onClick={this.handleExpandClick}
            aria-expanded={this.state.expanded}
            aria-label="Show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
        <Divider className={classes.divider} />
      </Fragment>
    )
  }
}

ACollapse.propTypes = {
  /** The styling classes */
  classes: PropTypes.object.isRequired,
  /** The collapse elements children nodes */
  children: PropTypes.node.isRequired,
  /** Text that shows as headline for collapse element */
  headline: PropTypes.string.isRequired,
  /** Adds Premium Icon to collapse element */
  premium: PropTypes.bool,
  /** Opens the collapse container from start */
  open: PropTypes.bool,
}

export default withStyles(styles)(ACollapse)
