import React from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/styles/withStyles'
import MenuItem from '@material-ui/core/MenuItem'

import { setMessage } from '../../actions'

import { intlIdToString } from '../00-utils/u-translation'
import { checkImageSize } from '../00-utils/u-helper'
import AButton from '../01-atoms/a-button'
import AInput from '../01-atoms/a-input'
import AIconPremium from '../01-atoms/a-icon-premium'
import ASelect from '../01-atoms/a-select'
import ASelectMulti from '../01-atoms/a-select-multi'
import ACollapse from '../01-atoms/a-collapse'
import AEditor from '../01-atoms/a-editor'
import AForm from '../01-atoms/a-form'
import ALoader from '../01-atoms/a-loader'
import MProfileMedia from '../02-molecules/m-profile-media'
import MProfileReferences from '../02-molecules/m-profile-references'

import { Html5Entities } from 'html-entities'
const htmlEntities = new Html5Entities()

const styles = theme => ({
  buttonClass: {
    ...theme.customStyles.profileSubmit,
  },
  headline: {
    fontSize: theme.typography.pxToRem(30),
    lineHeight: theme.typography.pxToRem(34),
    fontWeight: 500,
    marginBottom: theme.typography.pxToRem(15),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(40),
      lineHeight: theme.typography.pxToRem(44),
    },
  },
  description: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(24),
    fontWeight: 300,
    color: theme.customStyles.extraColors.darkGrey,
    marginBottom: theme.typography.pxToRem(30),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(25),
      lineHeight: theme.typography.pxToRem(33),
    },
  },
  hintContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.typography.pxToRem(40),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.typography.pxToRem(80),
    },
    '& p': {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(24),
      marginLeft: theme.typography.pxToRem(15),
      flex: 'auto',
      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.pxToRem(18),
        lineHeight: theme.typography.pxToRem(28),
      },
    },
  },
  hint: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.customStyles.extraColors.lightGrey,
    marginTop: theme.typography.pxToRem(20),
  },
  importantHint: {
    color: theme.palette.primary.main,
  },
})

class OProfileForm extends AForm {
  constructor(props) {
    super(props)
    this.addGalleryImage = this.addGalleryImage.bind(this)
    this.removeGalleryImage = this.removeGalleryImage.bind(this)
    this.getGallery = this.getGallery.bind(this)
    this.getLogo = this.getLogo.bind(this)
    this.addLogo = this.addLogo.bind(this)
    this.removeLogo = this.removeLogo.bind(this)
    this.getReferences = this.getReferences.bind(this)
    this.addReference = this.addReference.bind(this)
    this.removeReference = this.removeReference.bind(this)
    this.handleChangeReferenceImage = this.handleChangeReferenceImage.bind(this)
    this.handleDeleteReferenceImage = this.handleDeleteReferenceImage.bind(this)
    this.handleChangeReference = this.handleChangeReference.bind(this)
  }

  afterSubmit() {
    let data = this.prepareTagsAndCategories(this.groupByName())
    data.user_id = this.props.userId

    this.props.profileSet({
      companyId: this.props.companyId,
      data: data,
      token: this.props.userToken,
    })
  }

  componentDidMount() {
    // make sure the form gets validated after loading
    this.formValidate()
    if (this.getReferences() === undefined) {
      this.addReference()
    }
  }

  /**
   * Prepares tag values for API post request. Replaces tagId with tag slug and categoryId with cat slug
   * @param  {Object} FieldObject field values
   * @return {Object} prepared field values
   */
  prepareTagsAndCategories(fieldValues) {
    if (fieldValues.company_tags && fieldValues.company_tags.length) {
      fieldValues.company_tags = fieldValues.company_tags.map(tagId => {
        return this.props.tags.mapped[tagId].slug
      }, this)
    }

    if (
      fieldValues.company_categories &&
      fieldValues.company_categories.length
    ) {
      fieldValues.company_categories = this.props.categories.mapped[
        fieldValues.company_categories
      ].slug
    }
    return fieldValues
  }

  /**
   * Prepares field values for API post request
   * @return {Object} grouped field values
   */
  groupByName() {
    const values = this.getValues(false)
    const grouped = {
      company_info: {},
      company_links: {},
    }

    for (var key in values) {
      // eslint-disable-next-line no-prototype-builtins
      if (values.hasOwnProperty(key)) {
        let target = key.split('.')

        if (target.length === 2) {
          grouped[target[0]][target[1]] = values[key]
        } else if (target.length == 1) {
          grouped[target[0]] = values[key]
        }
      }
    }

    return grouped
  }

  addLogo(e) {
    const file = Array.from(e.target.files)[0]
    if (typeof file != 'object') return

    if (checkImageSize(file) == false) {
      this.props.setMessage({
        code: 'image_size_exceeded',
        message: 'Sie können maximal 2MB große Bilder hochladen',
      })
      return false
    }

    let fileUrl = URL.createObjectURL(file)
    let updatedLogo = { src: fileUrl, file: file, status: 'new' }

    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        ['company_info.logo']: {
          error: false,
          value: updatedLogo,
        },
      },
    }))
  }

  removeLogo(e) {
    let updatedLogo = { id: e, status: 'deleted' }
    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        ['company_info.logo']: {
          error: false,
          value: updatedLogo,
        },
      },
    }))
  }

  getLogo() {
    return (
      ((this.state.fields || {})['company_info.logo'] || {}).value ||
      this.props.company.company_info.logo
    )
  }

  getGallery() {
    return (
      ((this.state.fields || {})['company_info.gallery'] || {}).value ||
      this.props.company.company_info.gallery
    )
  }

  addGalleryImage(e) {
    const files = Array.from(e.target.files)
    if (typeof files != 'object') return

    // Add max. 7 images
    if (files.length <= 7) {
      let updatedGallery = this.getGallery() ? this.getGallery() : []
      files.forEach((file, i) => {
        if (checkImageSize(file) == false) {
          this.props.setMessage({
            code: 'image_size_exceeded',
            message: 'Sie können maximal 2MB große Bilder hochladen',
          })
          return false
        }

        let fileUrl = URL.createObjectURL(file)
        updatedGallery.push({ id: i, src: fileUrl, file: file, status: 'new' })
        this.setState(prevState => ({
          fields: {
            ...prevState.fields,
            ['company_info.gallery']: {
              error: false,
              value: updatedGallery,
            },
          },
        }))
      })
    } else {
      this.props.setMessage({
        code: 'max_amout_of_images_exceeded',
        message: 'Sie können maximal 7 Bilder hochladen',
      })
    }
  }

  removeGalleryImage(e) {
    let gallery = this.getGallery()

    let updatedGallery = gallery
      .filter(image => {
        if (image.id == e && image.status && image.status == 'new') {
          return false
        } else {
          return image
        }
      })
      .map(image => {
        if (image.id == e) {
          image.status = 'deleted'
        }
        return image
      })

    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        ['company_info.gallery']: {
          error: false,
          value: updatedGallery,
        },
      },
    }))
  }

  getReferences() {
    return (
      ((this.state.fields || {})['company_info.references'] || {}).value ||
      this.props.company.company_info.references
    )
  }

  addReference() {
    let updatedReferences = this.getReferences() ? this.getReferences() : []
    updatedReferences.push({
      image: '',
      headline: '',
      text: '',
      link: '',
      status: 'new',
    })
    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        ['company_info.references']: {
          error: false,
          value: updatedReferences,
        },
      },
    }))
  }

  removeReference(e) {
    let updatedReferences = this.getReferences()
    updatedReferences[e].status = 'deleted'

    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        ['company_info.references']: {
          error: false,
          value: updatedReferences,
        },
      },
    }))
  }

  handleChangeReference(e, field, elem) {
    let updatedReferences = this.getReferences()
    updatedReferences[e][field] = elem.target.value
    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        ['company_info.references']: {
          error: false,
          value: updatedReferences,
        },
      },
    }))
  }

  handleChangeReferenceImage(e, i) {
    const file = Array.from(e.target.files)[0]

    if (typeof file != 'object') return

    if (checkImageSize(file) == false) {
      this.props.setMessage({
        code: 'image_size_exceeded',
        message: 'Sie können maximal 2MB große Bilder hochladen',
      })
      return false
    }

    let fileUrl = URL.createObjectURL(file)
    let updatedReferenceImage = { src: fileUrl, file: file, status: 'new' }

    let updatedReferences = this.getReferences()
    updatedReferences[i].image = updatedReferenceImage

    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        ['company_info.references']: {
          error: false,
          value: updatedReferences,
        },
      },
    }))
  }

  handleDeleteReferenceImage(e) {
    let updatedReferences = this.getReferences()
    updatedReferences[e].image.status = 'deleted'
    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        ['company_info.references']: {
          error: false,
          value: updatedReferences,
        },
      },
    }))
  }

  render() {
    const {
      categories,
      classes,
      company,
      isSending,
      settings,
      tags,
    } = this.props

    if (typeof company != 'object') {
      return <ALoader />
    }

    let links = {}
    if (
      typeof company.company_links == 'object' &&
      Object.keys(company.company_links).length
    ) {
      for (var key in company.company_links) {
        // eslint-disable-next-line no-prototype-builtins
        if (company.company_links.hasOwnProperty(key)) {
          links[company.company_links[key].slug] = company.company_links[key]
        }
      }
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8}>
            <Typography variant="h1" className={classes.headline}>
              <FormattedMessage
                id="editData"
                defaultMessage="Bearbeiten Sie Ihren Eintrag"
              />
            </Typography>
            <Typography component="p" className={classes.description}>
              Hier können Sie Ihren Firmenauftritt ganz nach Ihren Bedürfnissen
              anpassen und vervollständigen.
            </Typography>
            <div className={classes.hintContainer}>
              <AIconPremium medium />
              <Typography component="p">
                Die markierten Daten werden nur ausgegeben, wenn Sie Business
                oder Premium Kunde sind.
              </Typography>
            </div>
          </Grid>
        </Grid>
        <ACollapse headline="Ihre Firmendaten:" open>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <AInput
                error={this.fieldHasError('company_info.company')}
                label={intlIdToString('companyName')}
                name="company_info.company"
                onChange={this.handleChange.bind(this, ['isValidCompanyName'])}
                onEnter={name =>
                  this.fieldRegister(name, company.company_info.company)
                }
                required
                type="text"
                value={
                  this.state.fields['company_info.company'] !== undefined
                    ? this.state.fields['company_info.company'].value
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <AInput
                error={this.fieldHasError('company_info.street')}
                label={intlIdToString('streetAndNumber')}
                name="company_info.street"
                onChange={this.handleChange.bind(this, [
                  'isValidStreetNameAndNr',
                ])}
                onEnter={name =>
                  this.fieldRegister(
                    name,
                    typeof company.company_info.street == 'string'
                      ? company.company_info.street
                      : ''
                  )
                }
                required
                type="text"
                value={
                  this.state.fields['company_info.street'] !== undefined
                    ? this.state.fields['company_info.street'].value
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <AInput
                error={this.fieldHasError('company_info.plz')}
                label={intlIdToString('zipCode')}
                name="company_info.plz"
                onChange={this.handleChange.bind(this, ['isValidZipCode'])}
                onEnter={name =>
                  this.fieldRegister(
                    name,
                    typeof company.company_info.plz == 'string'
                      ? company.company_info.plz
                      : ''
                  )
                }
                required
                type="number"
                value={
                  this.state.fields['company_info.plz'] !== undefined
                    ? this.state.fields['company_info.plz'].value
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <AInput
                error={this.fieldHasError('company_info.city')}
                label={intlIdToString('city')}
                name="company_info.city"
                onChange={this.handleChange.bind(this, ['isValidCityName'])}
                onEnter={name =>
                  this.fieldRegister(
                    name,
                    typeof company.company_info.city == 'string'
                      ? company.company_info.city
                      : ''
                  )
                }
                required
                type="text"
                value={
                  this.state.fields['company_info.city'] !== undefined
                    ? this.state.fields['company_info.city'].value
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <AInput
                error={this.fieldHasError('company_info.country')}
                label={intlIdToString('country')}
                name="company_info.country"
                onChange={this.handleChange.bind(this, ['isValidCountryName'])}
                onEnter={name =>
                  this.fieldRegister(
                    name,
                    typeof company.company_info.country == 'string'
                      ? company.company_info.country
                      : ''
                  )
                }
                required
                type="text"
                value={
                  this.state.fields['company_info.country'] !== undefined
                    ? this.state.fields['company_info.country'].value
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <AInput
                error={this.fieldHasError('company_info.phone')}
                label={intlIdToString('phoneNumber')}
                name="company_info.phone"
                onChange={this.handleChange.bind(this, [
                  'isValidTelephoneNumber',
                ])}
                onEnter={name =>
                  this.fieldRegister(
                    name,
                    typeof company.company_info.phone == 'string'
                      ? company.company_info.phone
                      : ''
                  )
                }
                required={false}
                type="text"
                value={
                  this.state.fields['company_info.phone'] !== undefined
                    ? this.state.fields['company_info.phone'].value
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <AInput
                error={this.fieldHasError('company_info.fax')}
                label={intlIdToString('faxNumber')}
                name="company_info.fax"
                onChange={this.handleChange.bind(this, [
                  'isValidTelephoneNumber',
                ])}
                onEnter={name =>
                  this.fieldRegister(
                    name,
                    typeof company.company_info.fax == 'string'
                      ? company.company_info.fax
                      : ''
                  )
                }
                required={false}
                type="text"
                value={
                  this.state.fields['company_info.fax'] !== undefined
                    ? this.state.fields['company_info.fax'].value
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <AInput
                type="email"
                error={this.fieldHasError('company_info.email')}
                label={intlIdToString('email')}
                name="company_info.email"
                onChange={this.handleChange.bind(this, ['isValidMail'])}
                onEnter={name =>
                  this.fieldRegister(
                    name,
                    typeof company.company_info.email == 'string'
                      ? company.company_info.email
                      : ''
                  )
                }
                required
                value={
                  this.state.fields['company_info.email'] !== undefined
                    ? this.state.fields['company_info.email'].value
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <AInput
                type="url"
                error={this.fieldHasError('company_links.website')}
                label={intlIdToString('websiteLink')}
                name="company_links.website"
                onChange={this.handleChange.bind(this, ['isValidUrl'])}
                onEnter={name =>
                  this.fieldRegister(
                    name,
                    typeof links.website == 'object' ? links.website.url : ''
                  )
                }
                required={false}
                value={
                  this.state.fields['company_links.website'] !== undefined
                    ? this.state.fields['company_links.website'].value
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <AInput
                type="url"
                error={this.fieldHasError('company_links.press_link')}
                label={intlIdToString('pressLink')}
                name="company_links.press_link"
                onChange={this.handleChange.bind(this, ['isValidUrl'])}
                onEnter={name =>
                  this.fieldRegister(
                    name,
                    typeof links.press_link == 'object'
                      ? links.press_link.url
                      : ''
                  )
                }
                required={false}
                value={
                  this.state.fields['company_links.press_link'] !== undefined
                    ? this.state.fields['company_links.press_link'].value
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <AInput
                type="url"
                error={this.fieldHasError('company_links.blog')}
                label={intlIdToString('blogLink')}
                name="company_links.blog"
                onChange={this.handleChange.bind(this, ['isValidUrl'])}
                onEnter={name =>
                  this.fieldRegister(
                    name,
                    typeof links.blog == 'object' ? links.blog.url : ''
                  )
                }
                required={false}
                value={
                  this.state.fields['company_links.blog'] !== undefined
                    ? this.state.fields['company_links.blog'].value
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <AInput
                type="url"
                error={this.fieldHasError('company_links.facebook')}
                label={intlIdToString('facebookLink')}
                name="company_links.facebook"
                onChange={this.handleChange.bind(this, ['isValidUrl'])}
                onEnter={name =>
                  this.fieldRegister(
                    name,
                    typeof links.facebook == 'object' ? links.facebook.url : ''
                  )
                }
                required={false}
                value={
                  this.state.fields['company_links.facebook'] !== undefined
                    ? this.state.fields['company_links.facebook'].value
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <AInput
                type="url"
                error={this.fieldHasError('company_links.linkedin')}
                label={intlIdToString('linkedinLink')}
                name="company_links.linkedin"
                onChange={this.handleChange.bind(this, ['isValidUrl'])}
                onEnter={name =>
                  this.fieldRegister(
                    name,
                    typeof links.linkedin == 'object' ? links.linkedin.url : ''
                  )
                }
                required={false}
                value={
                  this.state.fields['company_links.linkedin'] !== undefined
                    ? this.state.fields['company_links.linkedin'].value
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <AInput
                label={intlIdToString('xingLink')}
                type="url"
                error={this.fieldHasError('company_links.xing')}
                name="company_links.xing"
                onChange={this.handleChange.bind(this, ['isValidUrl'])}
                onEnter={name =>
                  this.fieldRegister(
                    name,
                    typeof links.xing == 'object' ? links.xing.url : ''
                  )
                }
                required={false}
                value={
                  this.state.fields['company_links.xing'] !== undefined
                    ? this.state.fields['company_links.xing'].value
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <AInput
                label={intlIdToString('twitterLink')}
                type="url"
                error={this.fieldHasError('company_links.twitter')}
                name="company_links.twitter"
                onChange={this.handleChange.bind(this, ['isValidUrl'])}
                onEnter={name =>
                  this.fieldRegister(
                    name,
                    typeof links.twitter == 'object' ? links.twitter.url : ''
                  )
                }
                required={false}
                value={
                  this.state.fields['company_links.twitter'] !== undefined
                    ? this.state.fields['company_links.twitter'].value
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <AInput
                label={intlIdToString('githubLink')}
                type="url"
                error={this.fieldHasError('company_links.github')}
                name="company_links.github"
                onChange={this.handleChange.bind(this, ['isValidUrl'])}
                onEnter={name =>
                  this.fieldRegister(
                    name,
                    typeof links.github == 'object' ? links.github.url : ''
                  )
                }
                required={false}
                value={
                  this.state.fields['company_links.github'] !== undefined
                    ? this.state.fields['company_links.github'].value
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <AInput
                error={this.fieldHasError('company_info.founding_year')}
                label={intlIdToString('foundingYear')}
                name="company_info.founding_year"
                onChange={this.handleChange.bind(this, ['isValidFoundingYear'])}
                onEnter={name =>
                  this.fieldRegister(
                    name,
                    typeof company.company_info.founding_year == 'string'
                      ? company.company_info.founding_year
                      : ''
                  )
                }
                required={false}
                type="number"
                value={
                  this.state.fields['company_info.founding_year'] !== undefined
                    ? this.state.fields['company_info.founding_year'].value
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ASelect
                label={intlIdToString('companySize')}
                name="company_info.company_size"
                value={
                  this.state.fields['company_info.company_size'] !== undefined
                    ? this.state.fields['company_info.company_size'].value
                    : ''
                }
                onChange={this.handleChange.bind(this, [])}
                onEnter={name =>
                  this.fieldRegister(
                    name,
                    typeof company.company_info.company_size == 'string'
                      ? Object.keys(settings.company_size).find(key => {
                          return company.company_info.company_size == key
                        })
                      : ''
                  )
                }
              >
                <option key={-1} value="" disabled />
                {Object.keys(settings.company_size).map(function(key) {
                  return (
                    <option key={key} value={key}>
                      {settings.company_size[key]}
                    </option>
                  )
                })}
              </ASelect>
            </Grid>
          </Grid>
        </ACollapse>
        <ACollapse headline="Kategorien, Tags, Beschreibung">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <ASelect
                label={intlIdToString('categories')}
                name="company_categories"
                value={
                  this.state.fields.company_categories !== undefined
                    ? this.state.fields.company_categories.value
                    : ''
                }
                onChange={this.handleChange.bind(this, [])}
                onEnter={name =>
                  this.fieldRegister(
                    name,
                    company.company_categories !== null
                      ? company.company_categories
                      : ''
                  )
                }
              >
                <option key={-1} value="" disabled />
                {Object.keys(categories.mapped).map(function(key) {
                  return (
                    <option key={key} value={key}>
                      {htmlEntities.decode(categories.mapped[key].name)}
                    </option>
                  )
                })}
              </ASelect>
            </Grid>
            <Grid item xs={12} sm={8}>
              {
                <ASelectMulti
                  label={intlIdToString('tags')}
                  name="company_tags"
                  value={
                    this.state.fields.company_tags !== undefined
                      ? this.state.fields.company_tags.value
                      : []
                  }
                  onChange={this.handleChange.bind(this, [])}
                  onEnter={name =>
                    this.fieldRegister(
                      name,
                      typeof company.company_tags == 'object' &&
                        company.company_tags[0] != null
                        ? company.company_tags.map(catId => catId.toString())
                        : []
                    )
                  }
                >
                  {Object.keys(tags.mapped).map(function(key) {
                    return (
                      <MenuItem key={key} value={key}>
                        {tags.mapped[key].name}
                      </MenuItem>
                    )
                  })}
                </ASelectMulti>
              }
            </Grid>
            <Grid item xs={12} sm={8}>
              <AEditor
                label={intlIdToString('portfolio')}
                name="company_info.portfolio"
                onChangeParent={this.handleChange.bind(this, [])}
                onEnter={name =>
                  this.fieldRegister(
                    name,
                    typeof company.company_info.portfolio == 'string'
                      ? company.company_info.portfolio
                      : ''
                  )
                }
                value={
                  this.state.fields['company_info.portfolio'] !== undefined
                    ? this.state.fields['company_info.portfolio'].value
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <AEditor
                label={intlIdToString('aboutUs')}
                name="company_info.about_us"
                onChangeParent={this.handleChange.bind(this, [])}
                onEnter={name =>
                  this.fieldRegister(
                    name,
                    typeof company.company_info.about_us == 'string'
                      ? company.company_info.about_us
                      : ''
                  )
                }
                value={
                  this.state.fields['company_info.about_us'] !== undefined
                    ? this.state.fields['company_info.about_us'].value
                    : ''
                }
              />
            </Grid>
          </Grid>
        </ACollapse>
        <ACollapse headline="Medien" premium>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MProfileMedia
                logo={this.getLogo()}
                addLogo={this.addLogo}
                removeLogo={this.removeLogo}
                gallery={
                  this.getGallery()
                    ? this.getGallery().filter(
                        image => image.status != 'deleted'
                      )
                    : []
                }
                addGalleryImage={this.addGalleryImage}
                removeGalleryImage={this.removeGalleryImage}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AInput
                error={this.fieldHasError('company_info.video')}
                label={intlIdToString('companyVideo')}
                name="company_info.video"
                onChange={this.handleChange.bind(this, ['isValidCompanyVideo'])}
                onEnter={name =>
                  this.fieldRegister(name, company.company_info.video)
                }
                type="text"
                value={
                  this.state.fields['company_info.video'] !== undefined
                    ? this.state.fields['company_info.video'].value
                    : ''
                }
              />
              <Typography component="p" className={classes.hint}>
                <strong>
                  Bitte fügen Sie die{' '}
                  <span className={classes.importantHint}>EMBED url</span> von
                  youtube oder vimeo ein! (https://player.vimeo.com/video/xxxxx
                  oder https://www.youtube.com/embed/xxxxx)
                </strong>
              </Typography>
            </Grid>
          </Grid>
        </ACollapse>
        <ACollapse headline="Referenzen" premium>
          <Grid container spacing={3}>
            <MProfileReferences
              references={this.getReferences()}
              handleDeleteReferenceImage={this.handleDeleteReferenceImage}
              handleChangeReferenceImage={this.handleChangeReferenceImage}
              handleChangeReference={this.handleChangeReference}
              removeReference={this.removeReference}
              addReference={this.addReference}
            />
          </Grid>
        </ACollapse>
        <Grid container spacing={3}>
          <Grid item xs={12} align="center">
            <AButton
              className={classes.buttonClass}
              variant="contained"
              type="submit"
              disabled={isSending || !this.state.formValid}
              loading={isSending}
            >
              {intlIdToString('save')}
            </AButton>
          </Grid>
        </Grid>
      </form>
    )
  }
}

OProfileForm.defaultProps = {
  isSending: false,
}

OProfileForm.propTypes = {
  userId: PropTypes.number.isRequired,
  categories: PropTypes.object,
  company: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
  tags: PropTypes.object,
  settings: PropTypes.object,
  profileSet: PropTypes.func.isRequired,
  isSending: PropTypes.bool,
  setMessage: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    setMessage: message => dispatch(setMessage(message)),
  }
}

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(OProfileForm))
