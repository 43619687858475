import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/styles/withStyles'

const styles = theme => ({
  '@keyframes loading': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  loader: {
    animation: '$loading 1.4s infinite linear',
    background:
      'linear-gradient(to right, #000 10%, rgba(255, 255, 255, 0) 42%)',
    borderRadius: '50%',
    fontSize: 10,
    height: '11em',
    margin: '50px auto',
    position: 'relative',
    textIndent: '-9999em',
    transform: 'translateZ(0)',
    width: '11em',
    '&:before': {
      background: theme.customStyles.extraColors.black,
      borderRadius: '100% 0 0 0',
      content: '""',
      height: '50%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '50%',
    },
    '&:after': {
      background: theme.customStyles.extraColors.white,
      borderRadius: '50%',
      bottom: 0,
      content: '""',
      height: '75%',
      left: 0,
      margin: 'auto',
      position: 'absolute',
      right: 0,
      top: 0,
      width: '75%',
    },
  },
})

//const ALoader = classes => <div className={classes.loader} />

/** ALoader Component */
class ALoader extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes } = this.props
    return <div className={classes.loader} />
  }
}

ALoader.propTypes = {
  color: PropTypes.string,
  duration: PropTypes.number,
  size: PropTypes.number,
  classes: PropTypes.object,
}

export default withStyles(styles)(ALoader)
