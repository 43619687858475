import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/styles/withStyles'

import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { mailRequest } from '../../actions'

import OPackageChangeForm from '../03-organisms/o-package-change-form'
import Grid from '@material-ui/core/Grid'

import APaper from '../01-atoms/a-paper'
import MIconHeadlineTextLine from '../02-molecules/m-icon-headline-text-line'

const styles = theme => ({
  header: {
    marginBottom: '20px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '40px',
    },
  },
})

class ChangePackagePage extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes, successSet } = this.props

    const content = successSet ? (
      <Grid container spacing={5} justify="center" className={classes.header}>
        <Grid item xs={12} sm={10}>
          <Typography component="p">
            Vielen Dank für Ihre Anfrage zu den angebotenen Paketen. Ihr
            Änderungswunsch ist bei unserem Service-Team eingegangen. Wir werden
            uns schnellstmöglich mit Ihnen in Verbindung setzen.
          </Typography>
        </Grid>
      </Grid>
    ) : (
      <OPackageChangeForm
        userId={this.props.data.login.user_id}
        mailRequest={this.props.mailRequest}
        userToken={this.props.data.login.token}
      />
    )
    return (
      <APaper>
        <Grid container spacing={5} justify="center" className={classes.header}>
          <Grid item xs={12} sm={10}>
            <MIconHeadlineTextLine>
              <Typography variant="h2">Ihr Paketmodell</Typography>
              <Typography component="p">
                Wenn Sie ihr Paket ändern möchten, klicken Sie auf den
                entsprechenden Button. Es wird dann eine E-Mail mit dem
                Änderungswunsch an uns geschickt und wir kontaktieren Sie nach
                Prüfung.
              </Typography>
            </MIconHeadlineTextLine>
          </Grid>
        </Grid>
        {content}
      </APaper>
    )
  }
}

ChangePackagePage.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object.isRequired,
  mailRequest: PropTypes.func.isRequired,
  successSet: PropTypes.bool.isRequired,
}

const mapStateToProps = state => {
  return {
    data: {
      apiResults: state.apiResults,
      currentlySending: state.currentlySending,
      login: state.login,
    },
    successSet: state.lastCall == 'changePackage' && state.apiResults != false,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    mailRequest: data => dispatch(mailRequest(data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ChangePackagePage))
