import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import { connect } from 'react-redux'
import { mailRequest } from '../../actions'

import APaper from '../01-atoms/a-paper'
import OProfileContactForm from '../03-organisms/o-profile-contact-form.js'

class ContactPage extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <APaper>
        <Grid container spacing={5} justify="center">
          <Grid item xs={12}>
            <h1>
              <FormattedMessage id="contact" defaultMessage="Kontakt" />
            </h1>
            <OProfileContactForm
              isSending={this.props.data.currentlySending}
              contactEmail={this.props.data.login.user_email}
              userId={this.props.data.login.user_id}
              mailRequest={this.props.mailRequest}
              userToken={this.props.data.login.token}
            />
          </Grid>
        </Grid>
      </APaper>
    )
  }
}

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
  mailRequest: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  return {
    data: {
      apiResults: state.apiResults,
      currentlySending: state.currentlySending,
      login: state.login,
    },
  }
}

const mapDispatchToProps = dispatch => {
  return {
    mailRequest: data => dispatch(mailRequest(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactPage)
