import React from 'react'
import { PropTypes } from 'prop-types'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/styles/withStyles'

import { intlIdToString } from '../00-utils/u-translation'
import AButton from '../01-atoms/a-button.js'
import AInput from '../01-atoms/a-input.js'
import AForm from '../01-atoms/a-form'

const styles = theme => ({
  buttonClass: {
    ...theme.customStyles.profileSubmit,
  },
})

class OPasswordChangeForm extends AForm {
  afterSubmit() {
    const data = this.getValues(false)
    const userId = this.props.userId
    const userToken = this.props.userToken
    this.props.passwordChange({ data, userId, userToken })
  }

  render() {
    const { classes, isSending } = this.props
    return (
      <form
        onSubmit={this.handleSubmit}
        className={this.state.formValid ? '' : 'has-error'}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={7}>
            <AInput
              error={this.fieldHasError('passold')}
              label={intlIdToString('oldPassword')}
              name="passold"
              onChange={this.handleChange.bind(this, ['isValidPassword'])}
              onEnter={name => this.fieldRegister(name)}
              required
              type="password"
              value={
                this.state.fields.passold !== undefined
                  ? this.state.fields.passold.value
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <AInput
              error={this.fieldHasError('pass1')}
              label={intlIdToString('newPassword')}
              name="pass1"
              onChange={this.handleChange.bind(this, ['isValidPassword'])}
              onEnter={name => this.fieldRegister(name)}
              required
              type="password"
              value={
                this.state.fields.pass1 !== undefined
                  ? this.state.fields.pass1.value
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <AInput
              error={this.fieldHasError('pass2')}
              label={intlIdToString('repeatPassword')}
              name="pass2"
              onChange={this.handleChange.bind(this, ['isValidPassword'])}
              onEnter={name => this.fieldRegister(name)}
              required
              type="password"
              value={
                this.state.fields.pass2 !== undefined
                  ? this.state.fields.pass2.value
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12} align="center">
            <AButton
              className={classes.buttonClass}
              variant="contained"
              type="submit"
              disabled={isSending || !this.state.formValid}
              loading={isSending}
            >
              {intlIdToString('save')}
            </AButton>
          </Grid>
        </Grid>
      </form>
    )
  }
}

OPasswordChangeForm.propTypes = {
  oldPasswordValue: PropTypes.string,
  newPasswordValue: PropTypes.string,
  newPasswordRepeatValue: PropTypes.string,
}

export default withStyles(styles)(OPasswordChangeForm)
