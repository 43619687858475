import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'

/** ATextarea */
class ATextarea extends Component {
  componentDidMount() {
    // check if onEnter prop is set, if set: execute the function
    if (this.props.onEnter !== false) this.props.onEnter(this.props.name)
  }
  render() {
    let {
      label,
      disabled,
      rows,
      name,
      placeholder,
      value,
      onChange,
      helperText,
      required,
    } = this.props
    return (
      <TextField
        disabled={disabled}
        label={label}
        multiline
        fullWidth
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        rows={rows}
        helperText={helperText}
        value={value}
        required={required}
      />
    )
  }
}

ATextarea.propTypes = {
  /** @param {Boolean} disabled If true, the input will be disabled.*/
  disabled: PropTypes.bool,
  /** @param {Node} label The label content.*/
  label: PropTypes.node,
  /** @param {string} name Name attribute of the input element. */
  name: PropTypes.string,
  /** @param {func} onChange Callback fired when the value is changed. */
  onChange: PropTypes.func,
  /** Function to execute on componentDidMount, passes this.props.name */
  onEnter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  /** @param {string} placeholder The short hint displayed in the input before the user enters a value. */
  placeholder: PropTypes.string,
  /** @param {union: string | number} rows Number of rows to display when multiline option is set to true. */
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** @param {string} value The value of the Input element, required for a controlled component. */
  value: PropTypes.string,
  /** @param {string} helperText The helper text content. */
  helperText: PropTypes.string,
  /** @param {Boolean} required ... is field required or not?*/
  required: PropTypes.bool,
}

export default ATextarea
