import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
// import ASpinner from '../01-atoms/a-spinner'
import AGridList from '../01-atoms/a-grid-list'
import ARemove from '../01-atoms/a-icon-remove'

const styles = theme => ({
  container: {
    marginBottom: theme.typography.pxToRem(40),
  },
  input: {
    display: 'none',
  },
  description: {
    marginBottom: theme.typography.pxToRem(30),
  },
  button: {
    backgroundColor: theme.customStyles.extraColors.darkGrey,
    color: theme.customStyles.extraColors.white,
    minWidth: theme.typography.pxToRem(215),
    marginBottom: theme.typography.pxToRem(30),
  },
  logoContainer: {
    display: 'flex',
  },
  remove: {
    alignSelf: 'center',
  },
  logo: {
    alignSelf: 'center',
    width: 215,
    height: 215,
    border: '1px solid grey',
    display: 'flex',
    '& img': {
      width: 'auto',
      height: 'auto',
      maxWidth: '100%',
      maxHeight: '100%',
      alignSelf: 'center',
      margin: '0 auto',
    },
  },
})

/** MProfileMedia Component */
class MProfileMedia extends Component {
  constructor(props) {
    super(props)
  }

  onChange(e) {
    if (typeof this.props.addGalleryImage === 'function') {
      this.props.addGalleryImage(e)
    }
  }

  onChangeLogo(e) {
    if (typeof this.props.addLogo === 'function') {
      this.props.addLogo(e)
    }
  }

  render() {
    const {
      classes,
      gallery,
      logo,
      removeGalleryImage,
      removeLogo,
    } = this.props
    return (
      <Fragment>
        <div className={classes.container}>
          <Typography component="p" className={classes.description}>
            Logo Upload (Bild sollte mindestens 400px breit und maximal 2 MB
            groß sein, Format: png, jpg, jpeg)
          </Typography>
          <input
            accept="image/jpg, image/jpeg, image/png"
            className={classes.input}
            id="file-upload-logo"
            type="file"
            onChange={e => this.onChangeLogo(e)}
          />
          <label htmlFor="file-upload-logo">
            <Button
              variant="contained"
              component="span"
              className={classes.button}
            >
              Logo Upload
            </Button>
          </label>
          <br />
          {logo && logo.status != 'deleted' ? (
            <div className={classes.logoContainer}>
              <div className={classes.logo}>
                <img src={logo.src} srcSet={logo.srcset} title={logo.title} />
              </div>
              <ARemove
                className={classes.remove}
                remove={removeLogo}
                functionParam={logo.id}
              />
            </div>
          ) : (
            ''
          )}
        </div>
        <div className={classes.container}>
          <Typography component="p" className={classes.description}>
            Bildergalerie (Bild sollte mindestens 1000px breit und maximal 2 MB
            groß sein, Format: png, jpg, jpeg)
            <br />
            Sie können maximal 7 Bilder hochladen
          </Typography>
          {gallery.length ? (
            <AGridList
              tileData={gallery}
              preview
              removeGalleryImage={removeGalleryImage}
            />
          ) : (
            []
          )}
          {gallery.length < 7 ? (
            <Fragment>
              <input
                accept="image/jpg, image/jpeg, image/png"
                className={classes.input}
                id="file-upload-gallery"
                multiple
                type="file"
                onChange={e => this.onChange(e)}
              />
              <label htmlFor="file-upload-gallery">
                <Button
                  variant="contained"
                  component="span"
                  className={classes.button}
                >
                  Images Upload
                </Button>
              </label>
            </Fragment>
          ) : (
            false
          )}
        </div>
      </Fragment>
    )
  }
}

MProfileMedia.propTypes = {
  classes: PropTypes.object.isRequired,
  addGalleryImage: PropTypes.func,
  removeGalleryImage: PropTypes.func,
  addLogo: PropTypes.func,
  removeLogo: PropTypes.func,
  gallery: PropTypes.array,
  logo: PropTypes.object,
  error: PropTypes.string,
}

export default withStyles(styles)(MProfileMedia)
