import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

import APaper from '../01-atoms/a-paper'

import { connect } from 'react-redux'
import {
  profileRequestGet,
  profileRequestSet,
  subscribeRequest,
} from '../../actions'

import OProfileForm from '../03-organisms/o-profile-form.js'
import Grid from '@material-ui/core/Grid'
import ALoader from '../01-atoms/a-loader'
import { globalNaming } from '../../../site-config'

class AccountProfilePage extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubscribe = this.handleSubscribe.bind(this)
  }

  componentDidMount() {
    const { login } = this.props.data

    // console.log('account.js componentDidMount', this.props.data)
    if (login.user_role == 'company') {
      this.props.profileRequestGet({
        companyId: login.company_id,
        userId: login.user_id,
        token: login.token,
      })
    }
  }

  handleSubscribe() {
    const subscribeData = {
      userId: this.props.data.login.user_id,
      token: this.props.data.login.token,
    }
    this.props.subscribeRequest(subscribeData)
  }

  render() {
    const { data, successGet, successSet } = this.props

    let pageContent = ''

    if (data.login.user_role != 'company') {
      pageContent = (
        <Typography component="p" paragraph>
          Bitte loggen Sie sich mit ihrem Exhibitor Account ein
        </Typography>
      )
    } else if (data.login.status.registration === false) {
      pageContent = (
        <Typography component="p" paragraph>
          Sie haben sich noch nicht am {globalNaming.entryType}verzeichnis
          angemeldet.{' '}
          <a href="#" onClick={this.handleSubscribe}>
            Am {globalNaming.entryType}verzeichnis anmelden
          </a>
          .
        </Typography>
      )
    } else if (data.login.status.registration == 'pending') {
      pageContent = (
        <Typography component="p" paragraph>
          Ihr persönlicher Firmenaccount wird in Kürze durch unser Service-Team
          verifiziert und freigegeben. Sie erhalten bei Freigabe eine Mail und
          können direkt im Anschluss Ihre Profilseite anpassen.
        </Typography>
      )
    } else if (data.login.status.registration == 'removed') {
      pageContent = (
        <Typography component="p" paragraph>
          Ihr Account wurde von diesem {globalNaming.entryType}verzeichnis
          gelöscht. Sie können sich{' '}
          <a href="#" onClick={this.handleSubscribe}>
            wieder für dieses {globalNaming.entryType}verzeichnis anmelden
          </a>
          .
        </Typography>
      )
    } else if (!successSet && !successGet) {
      pageContent = <ALoader />
    } else if (data.apiResults.company instanceof Error) {
      pageContent = (
        <Typography component="p" paragraph>
          Ihr Profil konnte nicht geladen werden. Probieren Sie es später
          wieder.
        </Typography>
      )
    } else {
      pageContent = (
        <Fragment>
          <OProfileForm
            userId={data.login.user_id}
            categories={data.apiResults.category}
            company={
              typeof data.apiResults == 'object'
                ? data.apiResults.company
                : false
            }
            tags={data.apiResults.tag}
            settings={data.apiResults.settings}
            profileSet={this.props.profileRequestSet}
            companyId={data.login.company_id}
            userToken={data.login.token}
            isSending={data.currentlySending}
          />
        </Fragment>
      )
    }
    return (
      <APaper>
        <Grid container spacing={5} justify="center">
          <Grid item xs={12}>
            {pageContent}
          </Grid>
        </Grid>
      </APaper>
    )
  }
}

AccountProfilePage.propTypes = {
  data: PropTypes.object.isRequired,
  profileRequestGet: PropTypes.func.isRequired,
  profileRequestSet: PropTypes.func.isRequired,
  subscribeRequest: PropTypes.func.isRequired,
  successGet: PropTypes.bool.isRequired,
  successSet: PropTypes.bool.isRequired,
}

const mapStateToProps = state => {
  return {
    data: {
      apiResults: state.apiResults,
      currentlySending: state.currentlySending,
      login: state.login,
    },
    successGet: !state.currentlySending && state.lastCall == 'profileGet',
    successSet:
      !state.currentlySending &&
      state.lastCall == 'profileSet' &&
      state.apiResults != false,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    profileRequestGet: data => dispatch(profileRequestGet(data)),
    profileRequestSet: data => dispatch(profileRequestSet(data)),
    subscribeRequest: data => dispatch(subscribeRequest(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountProfilePage)
