/**
 * MPackageBox
 *
 *  @param {String} packagename - name of the package e.g. basic, business, premium.
 *  @param {String} price - price of the package e.g. 50,00 €/mtl.
 *  @param {String} icon - you can add an icon to the box for e.g. business and premium
 *  @param {String} buttonType - e.g. 'submit' to use button in form
 *
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import { intlIdToString } from '../00-utils/u-translation'
import AButton from '../01-atoms/a-button'
// import AIconBusiness from '../01-atoms/a-icon-business'
// import AIconPremium from '../01-atoms/a-icon-premium'

import withStyles from '@material-ui/styles/withStyles'

import { isUndefinedOrFunction } from '../00-utils/u-helper'

const styles = theme => ({
  container: {
    height: '100%',
    display: 'flex',
    padding: '0 20px 20px',
    backgroundColor: theme.customStyles.extraColors.boxGrey,
    borderTop: '20px solid white',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      padding: '0 46px 46px',
    },
  },
  badge: {
    display: 'block',
    lineHeight: 1.2,
    width: '100%',
    backgroundColor: theme.customStyles.extraColors.darkGrey,
    color: theme.customStyles.extraColors.white,
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 'bold',
    padding: theme.typography.pxToRem(10),
    marginTop: theme.typography.pxToRem(-20),
    marginBottom: theme.typography.pxToRem(36),
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(28),
    },
  },
  price: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(40),
    lineHeight: theme.typography.pxToRem(53),
    [theme.breakpoints.up('md')]: {
      fontSize: '2.8vw',
      lineHeight: theme.typography.pxToRem(53),
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.pxToRem(46),
    },
  },
  caption: {
    display: 'block',
    fontSize: theme.typography.pxToRem(14),
    color: theme.customStyles.extraColors.lightGrey,
    marginBottom: theme.typography.pxToRem(15),
  },
  divider: {
    marginBottom: theme.typography.pxToRem(15),
    backgroundColor: theme.customStyles.extraColors.darkGrey,
  },
  textContainer: {
    flex: '1 0 auto',
    '& p': {
      fontWeight: 'bold',
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(26),
    },
    '& ul': {
      paddingLeft: theme.typography.pxToRem(25),
      textAlign: 'left',
      listStyleType: 'none',
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(29),
      color: theme.customStyles.extraColors.lightGrey,
      '& li': {
        fontFamily: 'Roboto',
      },
    },
    '& svg': {
      fill: '#4A4A4A',
      width: theme.typography.pxToRem(14),
      height: theme.typography.pxToRem(12),
      marginLeft: theme.typography.pxToRem(-25),
      marginRight: theme.typography.pxToRem(11),
      verticalAlign: 'middle',
    },
  },
  addCompanyButton: {
    marginTop: theme.typography.pxToRem(20),
    textTransform: 'uppercase',
    padding: '0 30px',
  },
})

class MPackageBox extends Component {
  constructor(props) {
    super(props)
    this.state = { open: false }

    // This binding is necessary to make `this` work in the callback
    this.toggleOpenState = this.toggleOpenState.bind(this)
  }

  toggleOpenState() {
    this.setState(prevState => ({
      open: !prevState.open,
    }))
  }

  onClickHandler(e) {
    if (typeof this.props.addPackageType === 'function') {
      this.props.addPackageType(e)
    }
  }

  render() {
    const {
      classes,
      //icon,
      packagename,
      price,
      children,
      buttonType,
      buttonText,
    } = this.props

    // let iconEl
    // switch (icon) {
    //   case 'business': {
    //     iconEl = <AIconBusiness size="medium" />
    //     break
    //   }
    //   case 'premium': {
    //     iconEl = <AIconPremium size="medium" />
    //     break
    //   }
    //   default: {
    //     iconEl = ''
    //     break
    //   }
    // }

    const buttonEl =
      buttonType == 'submit' ? (
        <AButton
          key="0"
          className={classes.addCompanyButton}
          variant="contained"
          color="primary"
          type="submit"
          onClick={() => {
            this.onClickHandler(packagename)
          }}
        >
          {buttonText ? buttonText : intlIdToString('addCompany')}
        </AButton>
      ) : (
        <AButton
          key="0"
          className={classes.addCompanyButton}
          variant="contained"
          color="primary"
          to="/register"
        >
          {buttonText ? buttonText : intlIdToString('addCompany')}
        </AButton>
      )

    return (
      <div className={classes.container} align="center">
        <div className={classes.priceContainer}>
          <Typography variant="button" className={classes.badge}>
            {packagename}
          </Typography>
          <Typography variant="subtitle1" className={classes.price}>
            {price}
          </Typography>
          <Typography variant="caption" className={classes.caption}>
            alle Preise zzgl. MwSt.
          </Typography>
          <Divider className={classes.divider} />
        </div>

        <div className={classes.textContainer}>{children}</div>
        <div>
          {/* {iconEl} */}
          {buttonEl}
        </div>
      </div>
    )
  }
}

MPackageBox.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  packagename: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(['business', 'premium']),
  buttonType: PropTypes.string,
  addPackageType: isUndefinedOrFunction,
  buttonText: PropTypes.string,
}

export default withStyles(styles)(MPackageBox)
