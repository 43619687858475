/**
 * Packages
 *
 * @param {String} buttonType - e.g. 'submit' to use button in a form
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import MPackageBox from '../02-molecules/m-package-box'

import IconListItem from './../../assets/svgs/icon-list-item.svg'
import { packages } from '../../../site-config'

class Packages extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { buttonType, ...rest } = this.props
    const packageList =
      typeof packages == 'object' && packages.length
        ? packages.map((pack, i) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={i}>
                <MPackageBox
                  packagename={pack.packagename}
                  price={pack.price}
                  buttonType={buttonType}
                  {...rest}
                >
                  <ul>
                    {pack.listItems.map((list, i) => (
                      <li key={i}>
                        <IconListItem />
                        {list.item}
                      </li>
                    ))}
                  </ul>
                </MPackageBox>
              </Grid>
            )
          })
        : 'Please define your array of menu iconListEntries'

    return (
      <Grid container spacing={5} justify="center">
        {packageList}
      </Grid>
    )
  }
}

Packages.propTypes = {
  buttonType: PropTypes.string,
}

export default Packages
