import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withStyles from '@material-ui/styles/withStyles'

import IconPremium from './../../assets/svgs/icon-premium.svg'

const styles = theme => ({
  fabIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexShrink: '0',
    borderRadius: '50%',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  fabIconSmall: {
    width: theme.typography.pxToRem(32),
    height: theme.typography.pxToRem(32),
    '& svg': {
      width: theme.typography.pxToRem(24),
      height: theme.typography.pxToRem(24),
    },
  },
  fabIconMedium: {
    width: theme.typography.pxToRem(58),
    height: theme.typography.pxToRem(58),
    '& svg': {
      width: theme.typography.pxToRem(44),
      height: theme.typography.pxToRem(44),
    },
  },
})

/** ALine Component */
class AIconPremium extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let mergedClassNames
    const { classes, size } = this.props
    switch (size) {
      case 'small': {
        mergedClassNames = classNames(classes.fabIcon, classes.fabIconSmall)
        break
      }
      case 'medium': {
        mergedClassNames = classNames(classes.fabIcon, classes.fabIconMedium)
        break
      }
      default: {
        mergedClassNames = classNames(classes.fabIcon, classes.fabIconSmall)
        break
      }
    }

    return (
      <div className={mergedClassNames}>
        <IconPremium />
      </div>
    )
  }
}

AIconPremium.propTypes = {
  classes: PropTypes.object.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
}

export default withStyles(styles)(AIconPremium)
