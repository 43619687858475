import React from 'react'
import PropTypes from 'prop-types'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

/** ASelectMulti */
class ASelectMulti extends React.Component {
  componentDidMount() {
    // check if onEnter prop is set, if set: execute the function
    if (this.props.onEnter !== false) this.props.onEnter(this.props.name)
  }

  render() {
    const { label, name, value, onChange, children } = this.props
    return (
      <FormControl fullWidth>
        <InputLabel htmlFor="select-multiple">{label}</InputLabel>
        <Select
          displayEmpty
          multiple
          name={name}
          value={value}
          onChange={onChange}
          input={<Input id="select-multiple" />}
        >
          {children}
        </Select>
      </FormControl>
    )
  }
}

ASelectMulti.propTypes = {
  /** @param {Boolean} true if multiselect is active */
  multiple: PropTypes.bool,
  /** @param {Node} label for input fields*/
  label: PropTypes.node,
  /** @param {String} name, needs to be same then the state value name*/
  name: PropTypes.string,
  /** @param {String} value*/
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
      ])
    ),
  ]),
  /** @param {Function} onChange function running after change in element*/
  onChange: PropTypes.func,
  /** Function to execute on componentDidMount, passes this.props.name */
  onEnter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  /** @param {String} helperText The helper text content.*/
  helperText: PropTypes.string,
  /** The selects children nodes (options) */
  children: PropTypes.node,
}

export default ASelectMulti
