export function isUndefinedOrFunction(props, propName, componentName) {
  if (
    typeof props[propName] !== 'undefined' &&
    typeof props[propName] !== 'function'
  ) {
    return new Error(
      'Invalid prop `' +
        propName +
        '` supplied to' +
        ' `' +
        componentName +
        '`. Validation failed. Should be a function or undefined.'
    )
  }
}

export function isTagActive(tagId, companyOverviewFilter) {
  return companyOverviewFilter.tags.indexOf(tagId) !== -1
}

export function toggleFilterTag(tagId, companyOverviewFilter, companyRequest) {
  let filter

  if (isTagActive(tagId, companyOverviewFilter)) {
    filter = {
      ...companyOverviewFilter,
      tags: companyOverviewFilter.tags.filter(el => el !== tagId),
      page: false,
    }
  } else {
    filter = {
      ...companyOverviewFilter,
      tags: [...companyOverviewFilter.tags, tagId],
      page: false,
    }
  }

  companyRequest({ query: 'all', filter: filter })
}

export function isActive(type, value, companyOverviewFilter) {
  return companyOverviewFilter[type] === value
}

export function toggleFilter(
  type,
  value,
  companyOverviewFilter,
  companyRequest
) {
  let filter
  if (isActive(type, value, companyOverviewFilter)) {
    filter = {
      ...companyOverviewFilter,
      [type]: false,
      page: false,
    }
  } else {
    filter = {
      ...companyOverviewFilter,
      [type]: value,
      page: false,
    }
  }
  companyRequest({ query: 'all', filter: filter })
}

export function checkImageSize(file) {
  let size = file.size

  if (size / 1024 / 1024 > 2) {
    return false
  }
  return true
}
