import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'

/** ASelect */
class ASelect extends Component {
  componentDidMount() {
    // check if onEnter prop is set, if set: execute the function
    if (this.props.onEnter !== false) this.props.onEnter(this.props.name)
  }

  render() {
    const {
      label,
      name,
      value,
      onChange,
      helperText,
      native,
      children,
    } = this.props
    return (
      <TextField
        name={name}
        select
        fullWidth
        label={label}
        value={value}
        onChange={onChange}
        helperText={helperText}
        SelectProps={{
          native: typeof native == 'boolean' ? native : true,
        }}
      >
        {children}
      </TextField>
    )
  }
}

ASelect.propTypes = {
  /** @param {Boolean} native html element */
  native: PropTypes.bool,
  /** @param {Node} label for input fields*/
  label: PropTypes.node,
  /** @param {String} name, needs to be same then the state value name*/
  name: PropTypes.string,
  /** @param {String} value*/
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  ]),
  /** @param {Function} onChange function running after change in element*/
  onChange: PropTypes.func,
  /** Function to execute on componentDidMount, passes this.props.name */
  onEnter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  /** @param {String} helperText The helper text content.*/
  helperText: PropTypes.string,
  /** The selects children nodes (options) */
  children: PropTypes.node,
}

export default ASelect
