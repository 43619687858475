import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import withStyles from '@material-ui/styles/withStyles'
import { navigate } from 'gatsby'
import { connect } from 'react-redux'
import auth from '../../auth'

import SimpleTemplateWrapper from '../../components/04-templates/t-simple-template-wrapper'
import AccountProfilePage from '../../components/05-pages/account'
import ChangePasswordPage from '../../components/05-pages/change-password'
import ContactPage from '../../components/05-pages/contact'
import DeleteAccountPage from '../../components/05-pages/delete-account'
import ChangePackagePage from '../../components/05-pages/change-package'
import { sideNavEntries } from '../../../site-config'

const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  scrollable: {
    ...theme.customStyles.mainContent,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  wrapper: {
    marginTop: theme.typography.pxToRem(-90),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.typography.pxToRem(-180),
    },
  },
  appBar: {
    backgroundColor: theme.customStyles.extraColors.black,
  },
  tabs: {
    [theme.breakpoints.up('md')]: {
      alignContent: 'stretch',
      flexWrap: 'wrap',
      paddingLeft: theme.typography.pxToRem(10),
      paddingRight: theme.typography.pxToRem(10),
    },
  },
  tab: {
    color: theme.customStyles.extraColors.white,
    fontWeight: 500,
    height: theme.typography.pxToRem(57),
    '&:last-of-type': {
      marginLeft: 'auto',
    },
  },
  tabsLabel: {
    fontSize: theme.typography.pxToRem(18),
  },
})
class AccountArea extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: 1,
    }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event, value) {
    this.setState({ value: value })
  }

  authCheck() {
    // check store and auth token
    const loggedIn = typeof this.props.data.login == 'object' && auth.loggedIn()

    // check if window is defined is needed for build process
    // because it's not defined while building
    if (typeof window == 'object' && !loggedIn) {
      // TODO: check why redirect to /login/ leads to infinite loop
      navigate('/')
    }

    return loggedIn
  }

  componentDidMount() {
    this.authCheck()
  }

  render() {
    this.authCheck()

    const { classes } = this.props
    const { value } = this.state
    return (
      <div className={classes.root}>
        <SimpleTemplateWrapper>
          <div className={classes.wrapper}>
            <AppBar position="static" elevation={0} className={classes.appBar}>
              <Tabs
                value={value}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
                classes={{
                  scrollable: classes.scrollable,
                  flexContainer: classes.tabs,
                }}
              >
                {sideNavEntries.map((entry, index) => {
                  return (
                    <Tab
                      key={index}
                      label={entry.label}
                      value={entry.entryNo}
                      classes={{
                        root: classes.tab,
                        wrapper: classes.tabsLabel,
                      }}
                    />
                  )
                })}
              </Tabs>
            </AppBar>
            {value === 1 && <AccountProfilePage />}
            {value === 2 && <ChangePasswordPage />}
            {value === 3 && <ContactPage />}
            {value === 4 && <ChangePackagePage />}
            {value === 5 && <DeleteAccountPage />}
          </div>
        </SimpleTemplateWrapper>
      </div>
    )
  }
}

AccountArea.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object.isRequired,
  profileRequest: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    data: {
      login: state.login,
    },
  }
}

export default connect(mapStateToProps)(withStyles(styles)(AccountArea))
