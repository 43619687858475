import React, { Component } from 'react'
import PropTypes from 'prop-types'
require('../../../node_modules/medium-editor/dist/css/medium-editor.css')
require('../../../node_modules/medium-editor/dist/css/themes/default.css')
import { intlIdToString } from '../00-utils/u-translation'
import withStyles from '@material-ui/styles/withStyles'

// ES module
import Editor from 'react-medium-editor'

const styles = theme => ({
  label: {
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
  description: {
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontStyle: 'italic',
    [theme.breakpoints.up('sm')]: {
      fontSize: '12px',
    },
  },
  textfield: {
    minHeight: '100px',
    outline: 'none',
    boxShadow: 'inset 0 0 2px #000000',
    padding: '10px',
  },
})

/** AEditor */
class AEditor extends Component {
  componentDidMount() {
    // check if onEnter prop is set, if set: execute the function
    if (this.props.onEnter !== false) this.props.onEnter(this.props.name)
  }

  handleChange(text, medium, e, type) {
    if (typeof this.props.onChangeParent !== 'function') return
    if (type === 'reference') {
      let event = {}
      event.target = {
        type: 'aeditor',
        name: e.props.name,
        value: text,
      }

      this.props.onChangeParent(event, e.props.index, 'text')
    } else {
      let event = {}
      event.target = {
        type: 'aeditor',
        name: e.props.name,
        value: text,
      }
      this.props.onChangeParent(event, [])
    }
  }
  render() {
    let { classes, value, name, label, type } = this.props
    return (
      <React.Fragment>
        <p className={classes.label}>{label}</p>
        <p className={classes.description}>
          {intlIdToString('mediumEditorDesc')}
        </p>
        <Editor
          className={classes.textfield}
          name={name}
          tag="div"
          value={value}
          text={value}
          onChange={(text, medium) =>
            this.handleChange(text, medium, this, type)
          }
          options={{
            toolbar: {
              buttons: [
                'bold',
                'italic',
                'anchor',
                'orderedlist',
                'unorderedlist',
                'removeFormat',
              ],
            },
            placeholder: false,
            disableDoubleReturn: true,
            elementsContainer: null,
          }}
        />
      </React.Fragment>
    )
  }
}

AEditor.propTypes = {
  /** @param {string} name Name attribute of the editor element. */
  name: PropTypes.string,
  /** @param {func} onChange Callback fired when the value is changed. */
  onChangeParent: PropTypes.func,
  /** Function to execute on componentDidMount, passes this.props.name */
  onEnter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  /** @param {string} value The value of the Input element, required for a controlled component. */
  value: PropTypes.string,
  /** @param {string} type of editor to enable different parent functions */
  type: PropTypes.string,
  /** @param {Node} label for editor fields*/
  label: PropTypes.node,
  /** @param {object} add classes for styling to elements */
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AEditor)
