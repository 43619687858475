import isUrl from 'is-url'

export function isValidMail(email) {
  if (email === undefined) return false

  return email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) !== null
}

// to be discussed
export function isValidCompanyName(companyName) {
  return companyName.length > 2
}

// to be discussed
export function isValidCompanyVideo(companyVideo) {
  return companyVideo.includes('vimeo') || companyVideo.includes('youtube')
}

// to be discussed
export function isValidStreetNameAndNr(companyName) {
  return someGenericLengthCheck(companyName)
}

// to be discussed
export function isValidCityName(cityName) {
  return someGenericLengthCheck(cityName)
}

// to be discussed
export function isValidCountryName(countryName) {
  return someGenericLengthCheck(countryName)
}

// to be discussed
export function isValidTelephoneNumber(phoneNumber) {
  return someGenericLengthCheck(phoneNumber)
}

export function isValidZipCode(zipcode) {
  return zipcode.match(/^[0-9]{4,5}$/) !== null
}

// currently only allows full url with protocol
export function isValidUrl(urlString) {
  return isUrl(urlString)
}

export function isValidFoundingYear(calendarYear) {
  return (
    calendarYear.match(/^[1-2][0-9]{3}$/) !== null &&
    calendarYear <= new Date().getFullYear()
  )
}

export function hasLength(aString) {
  return aString.length > 1
}

export function isTheSameValue(a, b) {
  return a == b
}

//use crazy formula here?
export function isValidPassword(password) {
  return password.length >= 4
}

export function isChecked(checked) {
  return checked == true
}

function someGenericLengthCheck(aString) {
  return aString.length >= 0
}
