import React, { Component } from 'react'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/styles/withStyles'

import ARemove from '../01-atoms/a-icon-remove'

const styles = theme => ({
  headline: {
    fontSize: '20px',
    fontWeight: '500',
    marginBottom: '20px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '25px',
      lineHeight: '33px',
    },
  },
  gridListContainer: {
    marginBottom: '30px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '50px',
    },
  },
  remove: {
    position: 'absolute',
    zIndex: 1,
    right: 0,
  },
})

/*
 *  Element Gallery has max 7 entries in data-center
 *  the cols-array is responsible for the arrangement of the images
 */
const cols = [8, 3, 5, 4, 4, 5, 3]
class AGridList extends Component {
  render() {
    const { classes, tileData, preview, removeGalleryImage } = this.props
    return (
      <div className={classes.gridListContainer}>
        {preview ? (
          ''
        ) : (
          <Typography variant="h2" className={classes.headline}>
            Bildergalerie
          </Typography>
        )}
        {tileData ? (
          <GridList cellHeight={327} cols={8} spacing={10}>
            {tileData.map(function(tile, i) {
              return (
                <GridListTile key={i} cols={cols[i] || 1}>
                  {preview ? (
                    <ARemove
                      className={classes.remove}
                      remove={removeGalleryImage}
                      functionParam={tile.id}
                    />
                  ) : (
                    ''
                  )}
                  <img src={tile.src} srcSet={tile.srcset} alt={tile.title} />
                </GridListTile>
              )
            })}
          </GridList>
        ) : (
          false
        )}
      </div>
    )
  }
}

AGridList.propTypes = {
  /** add classes for styling to elements */
  classes: PropTypes.object.isRequired,
  tileData: PropTypes.array.isRequired,
  preview: PropTypes.bool,
  removeGalleryImage: PropTypes.func,
}

export default withStyles(styles)(AGridList)
