import React from 'react'
import { FormattedMessage } from 'react-intl'

import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import { connect } from 'react-redux'
import { passwordChange } from '../../actions'

import APaper from '../01-atoms/a-paper'
import OPasswordChangeForm from '../03-organisms/o-password-change-form.js'

class ChangePasswordPage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { data, passwordChange } = this.props

    return (
      <APaper>
        <Grid container spacing={5} justify="center">
          <Grid item xs={12}>
            <h1>
              <FormattedMessage
                id="changePassword"
                defaultMessage="Passwort ändern"
              />
            </h1>
            <OPasswordChangeForm
              userId={data.login.user_id}
              userToken={data.login.token}
              isSending={data.currentlySending}
              passwordChange={passwordChange}
            />
          </Grid>
        </Grid>
      </APaper>
    )
  }
}

ChangePasswordPage.propTypes = {
  data: PropTypes.object.isRequired,
  passwordChange: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  return {
    data: {
      apiResults: state.apiResults,
      login: state.login,
      currentlySending: state.currentlySending,
    },
  }
}

const mapDispatchToProps = dispatch => {
  return {
    passwordChange: data => dispatch(passwordChange(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordPage)
