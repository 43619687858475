import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/styles/withStyles'

import { intlIdToString } from '../00-utils/u-translation'
import AButton from '../01-atoms/a-button'
import AInput from '../01-atoms/a-input'
import ATextarea from '../01-atoms/a-textarea'
import AForm from '../01-atoms/a-form'

const styles = theme => ({
  buttonClass: {
    ...theme.customStyles.profileSubmit,
  },
})

class OProfileContactForm extends AForm {
  constructor(props) {
    super(props)
  }

  afterSubmit() {
    const formInput = this.getValues(false)

    this.props.mailRequest({
      data: formInput,
      userId: this.props.userId,
      userToken: this.props.userToken,
    })
  }

  render() {
    const { classes, isSending } = this.props
    const subject = this.props.subject.length ? this.props.subject : ''

    return (
      <form
        onSubmit={this.handleSubmit}
        className={this.state.formValid ? '' : 'has-error'}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={7}>
            <AInput
              error={this.fieldHasError('subject')}
              label={intlIdToString('contactSubject')}
              name="subject"
              onChange={this.handleChange.bind(this, ['hasLength'])}
              onEnter={name => this.fieldRegister(name, subject)}
              type="text"
              value={
                this.state.fields.subject !== undefined
                  ? this.state.fields.subject.value
                  : ''
              }
              disabled={subject != ''}
              required
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <ATextarea
              error={this.fieldHasError('message')}
              label={intlIdToString('contactMessage')}
              name="message"
              onChange={this.handleChange.bind(this, ['hasLength'])}
              onEnter={name => this.fieldRegister(name)}
              type="text"
              value={
                this.state.fields.message !== undefined
                  ? this.state.fields.message.value
                  : ''
              }
              rows={6}
              required
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <AInput
              error={this.fieldHasError('replyToMail')}
              label={intlIdToString('contactEmail')}
              name="replyToMail"
              onChange={this.handleChange.bind(this, ['isValidMail'])}
              onEnter={name =>
                this.fieldRegister(name, this.props.contactEmail)
              }
              required
              type="email"
              value={
                this.state.fields.replyToMail !== undefined
                  ? this.state.fields.replyToMail.value
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12} align="center">
            <AButton
              className={classes.buttonClass}
              variant="contained"
              type="submit"
              disabled={isSending || !this.state.formValid}
              loading={isSending}
            >
              {intlIdToString('submit')}
            </AButton>
          </Grid>
        </Grid>
      </form>
    )
  }
}

OProfileContactForm.defaultProps = {
  subject: '',
}

OProfileContactForm.propTypes = {
  subject: PropTypes.string,
  contactMessage: PropTypes.string,
  contactEmail: PropTypes.string,
  userId: PropTypes.number.isRequired,
  userToken: PropTypes.string.isRequired,
  isSending: PropTypes.bool,
  mailRequest: PropTypes.func.isRequired,
}

export default withStyles(styles)(OProfileContactForm)
