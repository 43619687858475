import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/styles/withStyles'

const styles = {
  line: {
    width: '100px',
    borderBottom: '4px solid black',
    marginTop: '20px',
  },
}

/** ALine Component */
class ALine extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes } = this.props
    return <div className={classes.line} />
  }
}

ALine.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ALine)
