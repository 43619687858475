import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'

/** AInput */
class AInput extends Component {
  componentDidMount() {
    // check if onEnter prop is set, if set: execute the function
    if (this.props.onEnter !== false) this.props.onEnter(this.props.name)
  }

  render() {
    let {
      label,
      disabled,
      name,
      type,
      placeholder,
      value,
      onChange,
      helperText,
      error,
      required,
      InputProps,
      ...props
    } = this.props
    return (
      <TextField
        disabled={disabled}
        fullWidth
        label={label}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        value={value}
        helperText={helperText}
        error={error}
        required={required}
        InputProps={InputProps}
        {...props}
      />
    )
  }
}

AInput.propTypes = {
  /** @param {Boolean} disabled for inactive element */
  disabled: PropTypes.bool,
  /** @param {Node} label for input fields*/
  label: PropTypes.node,
  /** @param {String} name, needs to be same as the state value name*/
  name: PropTypes.string,
  /** @param {String} placeholder*/
  placeholder: PropTypes.string,
  /** @param {String} helperText The helper text content.*/
  helperText: PropTypes.string,
  /** @param {Function} onChange function running after change in element*/
  onChange: PropTypes.func,
  /** Function to execute on componentDidMount, passes this.props.name */
  onEnter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  /** @param {String} type e.g. text, password, email*/
  type: PropTypes.string,
  /** @param {String} value*/
  value: PropTypes.string,
  /** @param {Boolean} required ... is field required or not?*/
  required: PropTypes.bool,
  /** @param {Object} Object with properties */
  InputProps: PropTypes.object,
  /** @param {Boolean} error ... set field error state for different style*/
  error: PropTypes.bool,
}

AInput.defaultProps = {
  onEnter: false,
}

export default AInput
